import { JsonValue } from 'type-fest';

const isEnabled = (storage: 'sessionStorage' | 'localStorage') => {
  try {
    return storage in window && window.localStorage !== null && typeof Storage !== 'undefined';
  } catch (e) {
    return false;
  }
};

const appPrefix = process.env.NEXT_PUBLIC_APP_NAME;

/**
 * Получает значение ключа key из localStorage/sessionStorage
 */
const getItem = (passedKey: string, { version, isGlobal, isUserSpecific, type }: TStorageOptions = defaultOptions) => {
  const storage = type === 'session' ? 'sessionStorage' : 'localStorage';
  const prefixedKey = isGlobal ? passedKey : `${appPrefix}_${passedKey}`;

  if (!isEnabled(storage)) {
    return undefined;
  }

  const key =
    isUserSpecific && window.atiUser.profile
      ? `${prefixedKey}_${window.atiUser.profile?.ati_code}${window.atiUser.profile?.contact.id}`
      : prefixedKey;

  try {
    const stored = JSON.parse(window[storage].getItem(key) || '');

    if (version) {
      return version === stored.version ? stored.payload : undefined;
    }

    return stored;
  } catch (e) {
    return undefined;
  }
};

export type TStorageOptions = {
  version?: number;
  isGlobal?: boolean;
  isUserSpecific?: boolean;
  type?: 'session';
};

const defaultOptions = {
  version: 0,
  isGlobal: false,
};

/**
 * Сохраняет значение value в localStorage/sessionStorage с ключиком key
 */
const setItem = (
  passedKey: string,
  value: JsonValue,
  { version, isGlobal, isUserSpecific, type }: TStorageOptions = defaultOptions,
) => {
  const storage = type === 'session' ? 'sessionStorage' : 'localStorage';
  const prefixedKey = isGlobal ? passedKey : `${appPrefix}_${passedKey}`;

  if (!isEnabled(storage)) {
    return undefined;
  }

  const key =
    isUserSpecific && window.atiUser.profile
      ? `${prefixedKey}_${window.atiUser.profile?.ati_code}${window.atiUser.profile?.contact.id}`
      : prefixedKey;

  try {
    if (version) {
      return window[storage].setItem(
        key,
        JSON.stringify({
          version,
          payload: value,
        }),
      );
    }

    return window[storage].setItem(key, JSON.stringify(value));
  } catch (e) {
    return undefined;
  }
};

/**
 * Удаляет значение localStorage/sessionStorage с ключиком key
 */
const removeItem = (passedKey: string, { isGlobal, isUserSpecific, type }: TStorageOptions) => {
  const storage = type === 'session' ? 'sessionStorage' : 'localStorage';
  const prefixedKey = isGlobal ? passedKey : `${appPrefix}_${passedKey}`;

  if (!isEnabled(storage)) {
    return undefined;
  }

  const key =
    isUserSpecific && window.atiUser.profile
      ? `${prefixedKey}_${window.atiUser.profile?.ati_code}${window.atiUser.profile?.contact.id}`
      : prefixedKey;

  try {
    return window[storage].removeItem(key);
  } catch (e) {
    return undefined;
  }
};

export { getItem, setItem, removeItem };
