import axios from './axios';

function getTabsData() {
  return axios.get('/api/userfilters/tabs');
}

function deleteSavedFilterRequest(filterId: string) {
  return axios.delete(`/webapi/v1.0/loads/filters/${filterId}`);
}

function deleteLastSearchRequest(filterId: string) {
  return axios.delete(`/webapi/v1.0/loads/search/last-searches/${filterId}`);
}

export { getTabsData, deleteSavedFilterRequest, deleteLastSearchRequest };
