import { makeAutoObservable, reaction } from 'mobx';

import { defaultCurrencyId, paymentTypes, rateTypes } from 'constants/payment';
import { RootStore } from 'store/RootStore';
import { FloatField } from 'store/fileds/FloatField';
import { SelectField } from 'store/fileds/SelectField';

class Rate {
  root;
  value;
  type;
  currencyTypeId;

  constructor(root: RootStore) {
    this.root = root;
    this.value = new FloatField();
    this.type = rateTypes.newFormat;
    this.currencyTypeId = new SelectField(this.root.options.getRateTypes, this.countryCurrencyType);

    makeAutoObservable(this);

    reaction(
      () => Number(this.value.data) + this.currencyTypeId.data,
      () => {
        if (this.root.app.isDataSetting) return;

        if ((this.value.data || !this.currencyTypeId.isDefault) && !this.root.filter.extraParams.withRate) {
          this.root.filter.extraParams.addParam(paymentTypes.withRate);
        }
      },
    );

    reaction(
      () => this.root.dictionaries.data.currencyCountry.length + this.root.dictionaries.data.currencyTypes.length,
      () => {
        if (this.root.app.isDataSetting) return;

        if (!(this.root.dictionaries.data.currencyCountry.length && this.root.dictionaries.data.currencyTypes.length))
          return;

        if (this.root.dictionaries.data.currencyCountry.length + this.root.dictionaries.data.currencyTypes.length)
          if (this.countryCurrencyType && this.currencyTypeId.isDefault) {
            if (this.currencyTypeId.isDefault) {
              this.currencyTypeId.setOption(this.countryCurrencyType);
            }

            this.currencyTypeId.setDefaultOption(this.countryCurrencyType);
            return;
          }

        this.currencyTypeId.setOptionByValue(defaultCurrencyId);
        this.currencyTypeId.setCurrentOptionAsDefault();
      },
    );
  }

  get isDefault() {
    return this.value.isDefault && this.currencyTypeId.isDefault;
  }

  get requestData() {
    if (!this.value.data) return null;

    return {
      ...(this.type === rateTypes.newFormat && { currencyTypeId: this.currencyTypeId.data }),
      type: this.type,
      value: this.value.data,
    };
  }

  get countryCurrencyType() {
    const {
      profile,
      dictionaries: {
        data: { currencyCountry },
      },
    } = this.root;
    const currencyTypes = this.root.options.getRateTypes();

    const currencyId = currencyCountry.find(item => parseInt(item.countryId) === profile.data?.profile?.countryId)?.id;

    const currencyType = currencyTypes.find(currency => currency.id.toString() === currencyId);

    const currencyTypePerKm = currencyTypes.find(currency => currency.id.toString() === currencyType?.currencyIdPerKm);

    return currencyTypePerKm;
  }

  setType = (type: number) => {
    this.type = type;
  };
}

export { Rate };
