import isChildBitwiseForStrings from 'ati-utils/isChildBitwiseForStrings';
import BigNumber from 'bignumber.js';

import { fetchDictionary } from './api';
import { LocaleType, localization } from './i18n';
import { getEnv, snakeToCamelObj } from './utils';

export enum DictionariesNames {
  carTypes = 'carTypes',
  cargoTypes = 'cargoTypes',
  loadingTypes = 'loadingTypes',
  currencyTypes = 'currencyTypes',
  countries = 'countries',
  packTypes = 'packTypes',
  moneyTypes = 'moneyTypes',
  currencyCountry = 'currencyCountry',
}

class Marshak {
  locale: LocaleType;
  config: TMarshakConfig;
  env: string;
  adapters: {
    [key in DictionariesNames]: ((dictionary: any) => any) | null;
  };

  constructor(locale: LocaleType, config: TMarshakConfig) {
    this.locale = locale;
    this.env = getEnv();
    this.config = config;

    this.adapters = {
      carTypes: this.transformCarTypes,
      cargoTypes: this.transformCargoTypes,
      loadingTypes: this.transformLoadingTypes,
      currencyTypes: this.transformCurrencyTypes,
      countries: null,
      packTypes: this.transformPackTypes,
      moneyTypes: null,
      currencyCountry: null,
    };
  }

  static getInitialData = (marshakConfig: TMarshakConfig) => {
    const dictionariesNames = Object.keys(marshakConfig) as TDictionaryName[];
    const data = dictionariesNames.reduce((acc, dictionaryName) => {
      if (marshakConfig[dictionaryName]?.regular) acc[dictionaryName] = [];
      if (marshakConfig[dictionaryName]?.byIds) acc[`${dictionaryName}ByIds`] = {};

      return acc;
    }, {} as TMarshakData);

    return data;
  };

  get i18n() {
    return localization[this.locale];
  }

  transformLoadingTypes = (loadingTypes: TTransformedMarshakItem<TLoadingTypeMarshak>[]) =>
    loadingTypes.concat([
      {
        id: '65536',
        name: this.i18n.loadingTypes.notSpecified,
        short: this.i18n.loadingTypes.notSpecified,
      },
    ]);

  transformCarTypes = (carTypes: TTransformedMarshakItem<TCarTypeMarshak>[]) =>
    carTypes
      .map(type => ({ ...type, name: type.carType }))
      .map(type => {
        const children = carTypes.filter(anotherType => {
          const parent = new BigNumber(type.mask).toString(2);
          const child = new BigNumber(anotherType.mask).toString(2);

          return parent !== child && isChildBitwiseForStrings(parent, child);
        });

        return {
          ...type,
          children,
        };
      });

  transformCargoTypes = (cargoTypes: TTransformedMarshakItem<TCargoTypeMarshak>[]) =>
    cargoTypes.map(type => ({ ...type, name: type.cargoName }));

  transformCurrencyTypes = (currencyTypes: TTransformedMarshakItem<TCurrencyTypeMarshak>[]) =>
    currencyTypes.map(type => ({ ...type, name: type.currName, id: parseInt(type.id) }));

  transformPackTypes = (packTypes: TTransformedMarshakItem<TPackTypeMarshak>[]) =>
    packTypes.map(type => ({ ...type, short: type.shortName }));

  transformMarshakDictionary = (marshakDictionary: TDictionariesResponse) => {
    return marshakDictionary.map(item =>
      snakeToCamelObj({
        id: item.dictionary_item_id.toString(),
        ...item.attributes_dictionary,
      }),
    );
  };

  fetchDictionaries = async () => {
    const dictionariesNames = Object.keys(this.config) as TDictionaryName[];
    const promises = dictionariesNames.map(name => fetchDictionary(name, this.env, this.locale));
    const responses = await Promise.all(promises);
    const dictionaries = {} as TMarshakData;

    dictionariesNames.forEach((dictonaryName, index) => {
      const dictionary = this.transformMarshakDictionary(responses[index].data);
      const adapter = this.adapters[dictonaryName];
      const adaptedDictionary = adapter ? adapter(dictionary) : dictionary;

      if (this.config[dictonaryName]?.regular) dictionaries[dictonaryName] = adaptedDictionary;

      if (this.config[dictonaryName]?.byIds) {
        dictionaries[`${dictonaryName}ByIds`] = adaptedDictionary.reduce(
          (byIds: { [key: string]: TDictionary[number] }, item: TDictionary[number]) => {
            byIds[item.id] = item;
            return byIds;
          },
          {},
        );
      }
    });

    return dictionaries;
  };
}

export { Marshak };
