import BigNumber from 'bignumber.js';
import { makeAutoObservable } from 'mobx';

import { RootStore } from 'store/RootStore';
import { bitSumToArray } from 'utils/numbers';

export class LoadingType {
  root: RootStore;
  loadingTypes: Array<string>;

  constructor(root: RootStore) {
    this.root = root;
    this.loadingTypes = [];

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.loadingTypes.length === 0;
  }

  setLoadingTypes = (loadingTypes: string[]) => {
    this.loadingTypes = loadingTypes;
  };

  setLoadingTypesFromBitSum = (bitSum: string) => {
    const loadTypes = bitSumToArray(bitSum);
    this.setLoadingTypes(loadTypes.map(type => type.toString()));
  };

  clearLoadingTypes = () => {
    this.loadingTypes = [];
  };

  get requestData() {
    if (this.isDefault) return null;

    return this.loadingTypes.reduce((acc, curr) => acc.plus(new BigNumber(curr)), new BigNumber(0)).toString();
  }

  get requestUserFilterData() {
    let data = 0;
    if (this.requestData) {
      try {
        data = parseInt(this.requestData, 10);
      } catch {}
    }

    return data;
  }
}
