import { makeAutoObservable } from 'mobx';

import { LoadWithOffer, getLoadIdsWithOffer } from 'api/offers';
import { getLoadsByIds } from 'api/search';
import { RootStore } from 'store/RootStore';
import { SelectedLoads } from 'store/UIStore/SelectedLoads';
import { LoadType } from 'types/cargosApp/Load';

export class LoadsWithOffers {
  readonly #root: RootStore;
  loadsIdsWithOffers: LoadWithOffer[];
  loadsWithOffers: LoadType[];
  count: number = 0;
  selectedLoads: SelectedLoads;

  constructor(root: RootStore) {
    this.#root = root;
    this.loadsIdsWithOffers = [];
    this.loadsWithOffers = [];
    this.selectedLoads = new SelectedLoads();

    makeAutoObservable(this);
  }

  get loads() {
    if (this.loadsWithOffers.length) {
      return this.loadsWithOffers.map(load => ({
        ...load,
        checked: this.selectedLoads.ids.includes(load.id),
      }));
    }

    return [];
  }

  updateLoad = (updatedLoad: LoadType) => {
    const indexToUpdate = this.loadsWithOffers?.findIndex(load => load.id == updatedLoad.id);

    if (this.loadsWithOffers && typeof indexToUpdate === 'number') {
      this.loadsWithOffers[indexToUpdate] = updatedLoad;
    }
  };

  setCount = (count: number) => {
    this.count = count;
  };

  setLoads = (loads: LoadType[]) => {
    this.loadsWithOffers = loads;
  };

  setLoadsIdsWithOffers = (loads: LoadWithOffer[]) => {
    this.loadsIdsWithOffers = loads;
  };

  updateLoads = async (isForceUpdate: boolean = false) => {
    await this.fetchLoadsWithOffers(isForceUpdate);
  };

  fetchLoadsWithOffers = async (isForceUpdate: boolean = false) => {
    try {
      if (isForceUpdate || this.loadsIdsWithOffers.length === 0) {
        const { data: loadsIdsWithOffers } = await getLoadIdsWithOffer();

        this.setLoadsIdsWithOffers(loadsIdsWithOffers);
      }

      if (this.loadsIdsWithOffers.length > 0) {
        const startIndex =
          (this.#root.ui.pagination.pages.onlyWithOffers - 1) * this.#root.ui.pagination.loadsWithOffersPerPage.data;
        const endIndex = startIndex + this.#root.ui.pagination.loadsWithOffersPerPage.data;

        const paginatedLoadsIdsWithOffers = this.loadsIdsWithOffers.slice(
          startIndex,
          Math.min(endIndex, this.loadsIdsWithOffers.length),
        );

        const loadsIds = paginatedLoadsIdsWithOffers.map(load => load.LoadId);
        const { data } = await getLoadsByIds(loadsIds);

        this.setCount(this.loadsIdsWithOffers.length);
        this.setLoads(data.loads);
      }
    } catch (error) {
      this.#root.errors.setRetrievable({
        name: 'fetchLoadsWithOffers',
        message: this.#root.app.i18n.errors.getLoadsWithOffers,
      });
    }
  };
}
