import isChildBitwise from 'ati-utils/isChildBitwise';
import { AxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';

import { TFirmContact } from 'api/contact';
import {
  createFilter,
  deleteSubscription,
  editFilter,
  getSmsParams,
  getSubscriptionRates,
  getSubscriptionsNotificationsInfo,
  validateFilter,
} from 'api/userFilters';
import { commonBoardId } from 'constants/boards';
import { ChangeDate } from 'constants/changeDate';
import { UserRights } from 'constants/profile';
import { OldToSearch } from 'store/FilterStore/Mappers/OldToSearch';
import { AdditionalNotificationTypes } from 'store/OptionsStore/OptionsStore';
import { RootStore } from 'store/RootStore';
import { FilterMeta, SavedFilter } from 'store/TabsDataStore/TabsDataStore';
import { BooleanField } from 'store/fileds/BooleanField';
import { SelectField } from 'store/fileds/SelectField';
import { StringField } from 'store/fileds/StringField';
import { camelToSnakeObj, cleanEmpty, snakeToCamelObj, snakeToPascalObj } from 'utils/objects';

import { PopupIds } from '../../UIStore/Popups';
import { Channels } from '../Channels';
import { SavedFilterSummary } from '../SavedFilterSummary';
import { OpenFilterPopupOptions } from '../SavedFiltersStore';

type PopupMode = 'new' | 'newSubscribe' | 'edit' | 'lowBalance';

export class NewSavedFilterPopup {
  readonly #root: RootStore;
  isSaving: boolean = false;
  isCollective: BooleanField;
  name: StringField;
  isNameInvalid: boolean = false;
  filter?: IOldFilter;
  hasSubscription: BooleanField;
  smsParams: TSmsParamsResponse | null = null;
  subscriptionRates: TSubscriptionRates | null = null;
  validationResult: IValidateUserFilterResponse | null = null;
  channels: Channels;
  subscriptionsNotificationsInfo: number = 0;
  filterMeta: FilterMeta | null = null;
  isValidatingFilter: boolean = false;
  controller: AbortController;
  additionalNotifications: SelectField<AdditionalNotificationTypes>;
  contact: TFirmContact | null;
  options: OpenFilterPopupOptions | undefined;

  constructor(root: RootStore, options?: OpenFilterPopupOptions) {
    this.#root = root;

    this.isCollective = new BooleanField();
    this.hasSubscription = new BooleanField();
    this.name = new StringField({ default: '' });

    this.additionalNotifications = new SelectField(this.#root.options.getLoadNotificationsTypes);

    this.channels = new Channels(root);

    this.contact = null;

    this.controller = new AbortController();

    this.options = options;

    makeAutoObservable(this);
  }

  get hasSubscriptionTrialAvailable(): boolean {
    return this.validationResult?.isTrialVersionAvaliable ?? false;
  }

  get isTrialSubscriptionActivated() {
    /**
     * TODO: в новом методе проверки триала возращаются нормальные статусы триала
     * надо будет перейти на них
     */
    return Boolean(this.validationResult?.trialVersionEndDateTime);
  }

  get isEdit() {
    return (
      this.validationResult?.resultFilter?.id &&
      this.validationResult?.resultFilter.id !== '00000000-0000-0000-0000-000000000000'
    );
  }

  get isLowBalance() {
    return this.validationResult?.isUserBalanceTooLow && this.hasSubscription.value;
  }

  get mode(): PopupMode {
    if (this.isLowBalance) return 'lowBalance';
    if (!this.isEdit && this.hasSubscription.value) return 'newSubscribe';
    if (this.isEdit) return 'edit';

    return 'new';
  }

  get isChangesIncluded() {
    return (
      this.additionalNotifications.option.value === AdditionalNotificationTypes.edited ||
      this.additionalNotifications.option.value === AdditionalNotificationTypes.rateRased
    );
  }

  get sendInformationWithRateRased() {
    return this.additionalNotifications.option.value === AdditionalNotificationTypes.rateRased;
  }

  get isSaveDisabled() {
    const { subscriptions } = this.#root;
    if (!subscriptions.isCountersInitialized || subscriptions.isCountersLoading) {
      return true;
    }

    const willReachNotificationsLimit =
      this.hasSubscription.value &&
      (this.willReachDailyNotificationsLimit || this.willReachDailySubscriptionNotificationsLimit);

    return willReachNotificationsLimit || this.isNameInvalid || this.isValidatingFilter || this.isVirtuallyBlocked;
  }

  get subscribedFilter() {
    if (!this.validationResult?.resultFilter?.id || !this.#root.tabsData.savedFiltersById) return null;

    return this.#root.tabsData?.savedFiltersById[this.validationResult.resultFilter.id];
  }

  get hasOnlyPersonalBoards() {
    return Boolean(this.filter?.boardList?.length) && !this.filter?.boardList.includes(commonBoardId);
  }

  get hasFreeSubscription() {
    return this.hasOnlyPersonalBoards || Boolean(this.validationResult?.promotionSubscriptionEndDate);
  }

  get hasSubscriptionTrial(): boolean {
    return this.validationResult?.isTrialVersionAvaliable ?? false;
  }

  get ratesView() {
    if (this.filter?.boardList?.length === 1 && this.filter.boardList.includes(commonBoardId)) {
      return this.subscriptionRates?.rates;
    }

    return this.subscriptionRates?.ratesWithUserBoards;
  }

  get expectedNotificationsCount() {
    if (!this.ratesView) return 0;

    return this.isChangesIncluded ? this.ratesView.totalCount.count : this.ratesView.newLoads?.count;
  }

  get hasExpectedNotificationsCount() {
    return Boolean(this.expectedNotificationsCount);
  }

  get willReachDailySubscriptionNotificationsLimit() {
    if (!this.hasSubscription) return false;

    if (this.hasOnlyPersonalBoards || !this.hasExpectedNotificationsCount || !this.#root.subscriptions.counters) {
      return false;
    }

    return this.expectedNotificationsCount >= this.#root.subscriptions.counters?.dailySubscriptionNotificationsLimit;
  }

  get willReachDailyNotificationsLimit() {
    if (!this.hasSubscription || this.willReachDailySubscriptionNotificationsLimit) return false;

    if (this.hasOnlyPersonalBoards || !this.hasExpectedNotificationsCount || !this.#root.subscriptions.counters) {
      return false;
    }

    return (
      this.expectedNotificationsCount + this.subscriptionsNotificationsInfo >=
      this.#root.subscriptions.counters?.dailyNotificationsLimit
    );
  }

  get notificationsRate() {
    if (!this.ratesView) return null;

    return this.isChangesIncluded ? this.ratesView.totalCount.rate : this.ratesView.newLoads?.rate;
  }

  get isCollectiveFilter() {
    return this.isCollectiveDisabled || !this.hasRightsToEditCommonFilter ? false : this.isCollective.value;
  }

  get isCollectiveDisabled() {
    return this.hasSubscription.value;
  }

  get isCollectiveDisabledWithWarning() {
    return this.isCollectiveDisabled && this.isCollective.value;
  }

  get hasRightsToEditCommonFilter() {
    const hasRightsToEditCommonFilter = isChildBitwise(
      this.contact?.userRights.toString() || '',
      UserRights.CommonSearchFilterAccess,
    );

    return hasRightsToEditCommonFilter;
  }

  get isVirtuallyBlocked() {
    const hasUnlimitedNotifications =
      this.hasOnlyPersonalBoards || this.hasSubscriptionTrial || this.#root.profile.hasUnlimitedNotificationsLicense;

    return (
      this.hasSubscription.value &&
      this.validationResult?.isUserVirtualBlocked &&
      (!hasUnlimitedNotifications || this.channels.sms.value)
    );
  }

  setIsValidatingFilter = (isValidatingFilter: boolean) => {
    this.isValidatingFilter = isValidatingFilter;
  };

  setIsSaving = (isSaving: boolean) => {
    this.isSaving = isSaving;
  };

  setFilter = (filter: IOldFilter) => {
    this.filter = filter;
  };

  setFilterMeta = (filterMeta: FilterMeta) => {
    this.filterMeta = filterMeta;
  };

  setIsNameInvalid = (isNameInvalid: boolean) => {
    this.isNameInvalid = isNameInvalid;
  };

  handleFilter = async () => {
    const filter = this.getSaveFilterRequestData();

    if (!filter) return;

    this.setIsSaving(true);

    try {
      if (this.isEdit) {
        await editFilter(this.validationResult?.resultFilter.id, this.getEditFilterRequestData());
      } else {
        await createFilter(filter);
      }

      this.#root.ui.popups.close(PopupIds.filterPopup);
      await this.#root.tabsData.fetchTabsData();

      if (this.#root.subscriptions.isOnSlowPolling) {
        await this.#root.subscriptions.fetchCounters();
      }
    } catch (error) {
      const typedError = error as AxiosError<TCreateFilterError>;
      const message = typedError?.response?.data.errorList?.[0]?.reason;
      this.#root.errors.setRetrievable({
        name: 'saveFilterError',
        message: message || this.#root.app.i18n.errors.saveFilter,
      });
    } finally {
      this.setIsSaving(false);
    }
  };

  saveFilter = async () => {
    this.handleFilter();

    // this.setIsSaving(true);

    // const filter = this.getSaveFilterRequestData();

    // const isCollectiveWithSub = filter?.isCollective && filter.subscription;

    // if (isCollectiveWithSub) {
    //   filter.isCollective = false;
    // }

    // if (filter) {
    //   const useNewMethod = !this.hasSubscription.value;

    //   return saveFilter(filter, { useNewMethod })
    //     .then(async response => {
    //       if (isCollectiveWithSub) {
    //         filter.isCollective = true;
    //         filter.subscription = undefined;
    //         await saveFilter(filter, { useNewMethod });
    //       }

    //       if (useNewMethod) {
    //         this.#root.ui.popups.close(PopupIds.filterPopup);
    //         await this.#root.tabsData.fetchTabsData();

    //         if (this.#root.subscriptions.isOnSlowPolling) {
    //           await this.#root.subscriptions.fetchCounters();
    //         }
    //       } else {
    //         if (response.data.savingStatus === SavingFilterStatus.Success) {
    //           this.#root.ui.popups.close(PopupIds.filterPopup);
    //           await this.#root.tabsData.fetchTabsData();

    //           if (this.#root.subscriptions.isOnSlowPolling) {
    //             await this.#root.subscriptions.fetchCounters();
    //           }
    //         } else if (response.data.savingStatus === SavingFilterStatus.FilterNameDuplicate) {
    //           this.setIsNameInvalid(true);
    //         } else {
    //           this.#root.errors.setRetrievable({
    //             name: 'saveFilterError',
    //             message: response.data?.savingStatusText || '',
    //           });
    //         }
    //       }
    //     })
    //     .catch((error: AxiosError<SavedFilterError>) => {
    //       if (error.response?.data.error === 'required_unique_name') {
    //         this.setIsNameInvalid(true);
    //         return;
    //       }

    //       if (error.response?.data.error === 'json_validation_error') {
    //         const message = error.response.data.details?.length && error.response.data.details[0].reason;
    //         this.#root.errors.setRetrievable({
    //           name: 'saveFilterError',
    //           message: message || this.#root.app.i18n.errors.saveFilter,
    //         });
    //         return;
    //       }

    //       this.#root.errors.setRetrievable({
    //         name: 'saveFilterError',
    //         message: error.response?.data.reason || this.#root.app.i18n.errors.saveFilter,
    //       });
    //     })
    //     .finally(() => this.setIsSaving(false));
    // }
  };

  getEditFilterRequestData = () => {
    return {
      name: this.name.value.trim(),
      isCollective: this.isCollectiveFilter,
    };
  };

  getSaveFilterRequestData = (): ISaveUserFilterRequest | null => {
    if (this.filter) {
      const data = {
        ...this.getEditFilterRequestData(),
        filter: {
          ...cleanEmpty(this.filter),
        },
        // subscription: this.getSubscriptionRequestData(),
      };

      // TODO: Пока сделал так, нужно перейти на новый метод получения/валидации
      // и скорее всего это можно будет просто удалить.
      if (!data.filter.toRadius) delete data.filter.toRadius;
      if (!data.filter.fromRadius) delete data.filter.fromRadius;
      if (!data.filter.routeParams.enabled) delete data.filter.routeParams;
      if (!data.filter.ellipse.enabled) delete data.filter.ellipse;
      delete data.filter.rateType;
      delete data.filter.firmGeo;
      delete data.filter.hideHiddenLoads;

      // TODO: новый метод не разрешает передавать радиусы, если это не город,
      // но старый метод валидации возвращает всегда поле радиус со значением 0
      if (!this.#root.filter.from.isCityLocality) {
        data.filter.fromRadius = null;
      }

      // TODO: новый метод не разрешает передавать радиусы, если это не город,
      // но старый метод валидации возвращает всегда поле радиус со значением 0
      if (!this.#root.filter.to.isCityLocality) {
        data.filter.toRadius = null;
      }

      return data;
    }

    return null;
  };

  getSubscriptionRequestData = () => {
    if (!this.hasSubscription.value) return null;

    const data = {
      contacts: [
        {
          contactId: this.#root.profile.data?.profile?.contact.id,
          channels: this.channels.data,
        },
      ],
      isChangesIncluded: this.isChangesIncluded,
      sendInformationWithRateRased: this.sendInformationWithRateRased,
    };

    return snakeToPascalObj(cleanEmpty(data));
  };

  getValidateFilterRequestData(savedFilter?: SavedFilter): IValidateUserFilterRequest {
    return {
      filter: {
        ...(savedFilter ? (savedFilter?.filter as unknown as IOldFilter) : this.#root.filter.requestUserFilterData),
        exactFromGeos_tmp: null,
        exactToGeos_tmp: null,
        fromGeo_tmp: '',
        toGeo_tmp: '',
        fromTabs: false,
        hash: 0,
        hash2: 0,
      },
      id: savedFilter?.id,
    };
  }

  validateFilter = async (filter?: SavedFilter): Promise<boolean> => {
    this.setIsValidatingFilter(true);

    const validateFilterRequestData = this.getValidateFilterRequestData(filter);

    const handleError = (msg?: string) => {
      this.#root.savedFilters.closePopup();
      this.#root.errors.setRetrievable({
        name: 'validateFilterError',
        message: msg || 'Не удалось валидировать фильтр',
      });
    };

    try {
      const result = await validateFilter(validateFilterRequestData, { signal: this.controller.signal });

      this.validationResult = result.data;

      if (result.data.success) {
        const filterName = result.data.resultFilter.name;

        this.name.setValue(filterName);

        const { filter, ...filterMeta } = result.data.resultFilter;

        this.setFilter(filter);
        this.setFilterMeta(filterMeta);
        this.#root.mobileDevices.setData(result.data.userMobileDevices);

        if (this.isEdit) {
          this.fillPopupFromValidationResult();
        }

        return true;
      } else {
        handleError(result.data.message);
        return false;
      }
    } catch (error) {
      const typedError = error as AxiosError;
      if (typedError.message === 'canceled') return true;

      handleError();
      return false;
    } finally {
      this.setIsValidatingFilter(false);
    }
  };

  unsubscribe = async () => {
    try {
      await deleteSubscription(this.validationResult?.resultFilter.subscription.dynamicDescriptorId);
      await this.#root.tabsData.fetchTabsData();
    } catch (error) {
      this.#root.errors.setRetrievable({
        name: 'deleteSubscription',
        message: this.#root.app.i18n.errors.deleteSubscription,
      });
    }
  };

  fillPopupFromValidationResult = () => {
    if (!this.validationResult?.resultFilter) return;
    const { resultFilter } = this.validationResult;
    const { subscription } = resultFilter;

    this.isCollective.setData(resultFilter.isCollective);
    this.hasSubscription.setData(Boolean(resultFilter.subscription));

    if (subscription) {
      this.channels.setData(subscription?.contacts);
    }

    if (subscription?.isChangesIncluded)
      this.additionalNotifications.setOptionByValue(
        subscription?.sendInformationWithRateRased
          ? AdditionalNotificationTypes.rateRased
          : AdditionalNotificationTypes.edited,
      );
  };

  fetchSmsParams = async () => {
    try {
      const { data } = await getSmsParams();

      this.setSmsParams(data);
    } catch (error) {
      this.#root.errors.setRetrievable({ name: 'getSmsParams', message: this.#root.app.i18n.errors.getSmsParams });
    }
  };

  fetchSubscriptionRate = async () => {
    if (!this.filter) return;
    try {
      const { data } = await getSubscriptionRates(camelToSnakeObj(cleanEmpty(OldToSearch(this.filter))), {
        signal: this.controller.signal,
      });

      this.setSubscriptionRates(snakeToCamelObj(data));
    } catch (error) {
      const typedError = error as AxiosError;
      if (typedError.message === 'canceled') return;

      this.#root.errors.setRetrievable({
        name: 'getSubscriptionRates',
        message: this.#root.app.i18n.errors.getSubscriptionRates,
      });
    }
  };

  setSubscriptionRates = (rates: TSubscriptionRates) => {
    this.subscriptionRates = rates;
  };

  setSmsParams = (smsParams: TSmsParamsResponse) => {
    this.smsParams = smsParams;
  };

  fetchSubscriptionsNotificationsInfo = async () => {
    try {
      const { data } = await getSubscriptionsNotificationsInfo();

      this.setSubscriptionsNotificationsInfo(data);
    } catch (error) {
      this.#root.errors.setRetrievable({ name: 'getSmsParams', message: this.#root.app.i18n.errors.getSmsParams });
    }
  };

  setSubscriptionsNotificationsInfo = (subscriptionsNotificationsInfo: number) => {
    this.subscriptionsNotificationsInfo = subscriptionsNotificationsInfo;
  };

  fetchContact = async () => {
    try {
      const contact = await this.#root.profile.fetchContactData();

      if (contact) {
        this.setContact(contact);
      }
    } catch (error) {
      this.#root.errors.setRetrievable({ name: 'getContact', message: this.#root.app.i18n.errors.getContact });
    }
  };

  setContact = (contact: TFirmContact | null) => {
    this.contact = contact;
  };

  get summary() {
    return new SavedFilterSummary(this.#root, this.filter);
  }

  get summaryItems(): TSummaryItem[] {
    const items: TSummaryItem[] = [];

    const i18n = this.#root.app.i18n;
    const summary = this.summary;

    const addItem = (type: string, value?: string | null) => value && items.push({ type, value });

    addItem(i18n.route.from.label, summary.from);
    addItem(i18n.route.to.label, summary.to);
    addItem(i18n.dates.label, summary.loadDate);
    addItem(i18n.routeParams.ellips, summary.routeParams.ellipsis);
    addItem(i18n.routeParams.routeLength, summary.routeParams.routeLength);
    addItem(i18n.weight.label, summary.weight);
    addItem(i18n.volume.label, summary.volume);
    addItem(i18n.carTypes.label, summary.truckType);
    addItem(i18n.loadingTypes.label, summary.loadingTypes);
    addItem(i18n.payment.label, summary.rate);
    addItem(i18n.extraParams.label, summary.extraParams);
    addItem(i18n.dimensions.length.label, summary.length);
    addItem(i18n.dimensions.width.label, summary.width);
    addItem(i18n.dimensions.height.label, summary.height);
    addItem(i18n.dimensions.loadTypes.label, this.filter?.dogruz !== 0 ? summary.loadType : null);
    addItem(i18n.dimensions.palletes.label, summary.pallets);
    addItem(i18n.cargoTypes.label, summary.cargoTypes);
    addItem(i18n.boards.label, summary.boards.boards);
    addItem(i18n.boards.auction, summary.boards.auction);
    addItem(i18n.firms.stars, summary.firms.stars);
    addItem(i18n.firms.fromWhere, summary.firms.from);
    addItem(i18n.firms.firm, summary.firms.firm);
    addItem(i18n.firms.firms, summary.firms.list);
    addItem(i18n.sorting.sortBy, summary.sortingType);
    addItem(i18n.time.time, this.filter?.changeDate !== ChangeDate.any ? summary.changeDate : null);

    return items;
  }

  get nameErrorDescription() {
    if (this.isNameInvalid) {
      return this.#root.app.i18n.filterPopup.warnings.nameIsInvalid;
    }

    return undefined;
  }
}

type TSummaryItem = {
  type: string;
  value: string;
};
