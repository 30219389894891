import isChildBitwise from 'ati-utils/isChildBitwise';
import BigNumber from 'bignumber.js';
import { makeAutoObservable } from 'mobx';

import { closeCarTypes, openCarTypes } from 'constants/carTypes';
import { bitSumToArray } from 'utils/numbers';

import { RootStore } from '../../RootStore';

export class TruckType {
  root: RootStore;
  truckTypes: string[] = [];

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.truckTypes.length === 0;
  }

  setTruckTypes = (truckTypes: string[]) => {
    this.truckTypes = truckTypes;
  };

  setTruckTypesFromBitSum = (bitSum: string) => {
    const truckTypes = bitSumToArray(bitSum);
    this.setTruckTypes(truckTypes.map(type => type.toString()));
  };

  clearTruckTypes = () => this.setTruckTypes([]);

  get hasOnlyRareTruckTypes() {
    if (this.truckTypes.length === 0) {
      return false;
    }

    const hasSomeOpenTruckType = isChildBitwise(openCarTypes, this.bitSum);
    const hasSomeClosedTruckType = isChildBitwise(closeCarTypes, this.bitSum);

    return !(hasSomeOpenTruckType || hasSomeClosedTruckType);
  }

  get bitSum() {
    return this.truckTypes.reduce((acc, curr) => acc.plus(new BigNumber(curr)), new BigNumber(0)).toString();
  }

  get requestData() {
    if (this.isDefault) return null;

    return this.bitSum;
  }

  addParentCarTypes(truckTypesWithChildren: (TCarTypes[number] & { children: TCarTypes })[], truckTypes: string[]) {
    return truckTypesWithChildren
      .filter(type => type.children.every(child => truckTypes.some(value => value === child.mask)))
      .map(type => type.mask)
      .concat(truckTypes);
  }
}
