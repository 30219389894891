import { makeAutoObservable } from 'mobx';

export type OptionType<T> = {
  value: T;
  label: string;
};

class SelectField<T = any> {
  getOptions;
  defaultOption?: OptionType<T>;
  _option;

  constructor(getOptions: () => OptionType<T>[], defaultOption?: OptionType<T>) {
    this.defaultOption = defaultOption || getOptions()[0];

    this.getOptions = getOptions;
    this._option = this.defaultOption;

    makeAutoObservable(this);
  }

  get default() {
    return this.defaultOption || this.options[0];
  }

  get option() {
    return this.options.find(opt => opt.value === this._option?.value) || this.default;
  }

  get options() {
    return this.getOptions();
  }

  get isDefault() {
    return this.option?.value === this.default?.value;
  }

  get data(): T {
    return this.option?.value;
  }

  setCurrentOptionAsDefault = () => {
    this.setDefaultOption(this._option);
  };

  setDefaultOption = (option: OptionType<T>) => {
    this.defaultOption = option;
  };

  setOption = (option: OptionType<T>) => {
    this._option = option;
  };

  setOptionByValue = (value: T) => {
    const option = this.options.find(option => option.value === value);
    if (option) {
      this.setOption(option);
    }
  };

  setData = (key: keyof OptionType<T>, value: any) => {
    const [option] = this.options.filter(option => option[key] === value);

    if (option) {
      this.setOption(option);
    }
  };

  reset = () => {
    this._option = this.default;
  };
}

export { SelectField };
