export const paymentTypes = {
  withPrepayment: '2',
  withoutRate: '512',
  cash: '8',
  rateWithNDS: '1024',
  rateWithoutNDS: '2048',
  withRate: '1',
};

export const rateTypes = {
  rubPerKm: 1,
  rub: 2,
  rubThousands: 3,
  rubPerHour: 4,
  newFormat: 0,
};

export const defaultCurrencyId = 8;

export const rateTypesOldToNew = {
  [rateTypes.rubPerKm]: 8,
  [rateTypes.rub]: 1,
  [rateTypes.rubThousands]: 12,
  [rateTypes.rubPerHour]: 19,
};
