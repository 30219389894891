import { TUikitSupportedLocale } from 'localization/types';
import { GeoListType } from 'types/cargosApp/Geo';

import axios from './axios';

function getGeoCompletionList(prefix: string, locale: TUikitSupportedLocale = 'ru', geoTypes: number) {
  const localeMap = {
    ru: 'ru',
    en: 'eng',
  };

  return axios.post('/webapi/public/extendedsearch/v1/geo/', {
    prefix,
    geo_types: geoTypes,
    Language: localeMap[locale],
  });
}

function getPublicGeoLists() {
  return axios.get<GeoListType[]>('/webapi/public/firms/v1.0/lists/geo/global');
}

function getPrivateGeoLists() {
  return axios.get<GeoListType[]>('/webapi/firms/v1.0/lists/geo');
}

export { getGeoCompletionList, getPublicGeoLists, getPrivateGeoLists };
