import { JsonValue } from 'type-fest';

import { TStorageOptions, getItem, removeItem, setItem } from './localStorage';

export class Storage<SavedValue extends JsonValue> {
  key: string;
  options: TStorageOptions;

  constructor(key: string, options: TStorageOptions) {
    this.key = key;
    this.options = options;
  }

  get = (): SavedValue => getItem(this.key, this.options);

  set = (value: SavedValue) => {
    setItem(this.key, value, this.options);
  };

  remove = () => {
    removeItem(this.key, this.options);
  };
}

const storageKeys: {
  [name: string]: {
    key: string;
    options: TStorageOptions;
  };
} = {
  compactPrettyLoad: {
    key: 'ATI_loadsCompactView',
    options: {
      isGlobal: true,
    },
  },
  subscriptionPromoTimestamp: {
    key: 'subscriptionPromoTimestamp',
    options: {
      isGlobal: false,
      isUserSpecific: true,
    },
  },
  subscriptionPromoCounter: {
    key: 'subscriptionPromoCounter',
    options: {
      isGlobal: false,
      isUserSpecific: true,
      type: 'session',
    },
  },
  lastSearchedFilterStorage: {
    key: 'lastSearchedFilter',
    options: {
      isGlobal: false,
      isUserSpecific: true,
    },
  },
};

export const compactPrettyLoadStorage = new Storage<boolean>(
  storageKeys.compactPrettyLoad.key,
  storageKeys.compactPrettyLoad.options,
);

export const subscriptionPromoTimestampStorage = new Storage<number>(
  storageKeys.subscriptionPromoTimestamp.key,
  storageKeys.subscriptionPromoTimestamp.options,
);

export const subscriptionPromoCounterStorage = new Storage<number>(
  storageKeys.subscriptionPromoCounter.key,
  storageKeys.subscriptionPromoCounter.options,
);

export const lastSearchedFilterStorage = new Storage<string>(
  storageKeys.lastSearchedFilterStorage.key,
  storageKeys.lastSearchedFilterStorage.options,
);
