import { getBoards } from 'api/boards';
import { BoardType } from 'components/Board/types';
import type { RootStore } from 'store/RootStore';
import { BooleanField } from 'store/fileds/BooleanField';
import { snakeToCamelObj } from 'utils/objects';

import { Board } from './Board';

class BoardsRepo {
  root: RootStore;
  boards: Array<Board>;
  isBoardsUnavailable: BooleanField;

  constructor(root: RootStore) {
    this.root = root;
    this.boards = [];
    this.isBoardsUnavailable = new BooleanField();
  }

  setBoards = (boards: Array<Board>) => {
    this.boards = boards;
  };

  fetchBoards = async () => {
    try {
      const { data } = await getBoards();
      const normalizedBoards = data.map(
        (boardFromServer: BoardType) => new Board(this.root, snakeToCamelObj(boardFromServer)),
      );

      this.setBoards(normalizedBoards);

      return normalizedBoards;
    } catch (error) {
      console.error(error);

      this.isBoardsUnavailable.setData(true);
      this.root.errors.setRetrievable({ name: 'fetchBoards', message: this.root.app.i18n.errors.fetchBoardsError });
    }
  };
}

export { BoardsRepo };
