import { makeAutoObservable, reaction } from 'mobx';

import {
  deleteAllFavorites,
  deleteFavoritesById,
  deleteUnactualFavorites,
  getFavorites,
} from 'api/favorites/favorites';
import { enablerFeatureTitles } from 'constants/features';
import { tabIds } from 'constants/ui';
import { RootStore } from 'store/RootStore';
import { SelectedLoads } from 'store/UIStore/SelectedLoads';
import { LoadType } from 'types/cargosApp/Load';
import { snakeToCamelObj } from 'utils/objects';

const initialLoadingState = {
  removeSelectedFavorites: false,
};

export class Favorites {
  readonly #root: RootStore;
  favoriteLoads: LoadType[];
  deletedLoadsIds: string[];
  count: number = 0;
  selectedLoads: SelectedLoads;
  loading = initialLoadingState;

  constructor(root: RootStore) {
    this.#root = root;
    this.favoriteLoads = [];
    this.deletedLoadsIds = [];
    this.selectedLoads = new SelectedLoads();

    makeAutoObservable(this);

    reaction(() => JSON.stringify(this.loadsIds), this.handleLoadsDeletition);

    reaction(() => this.#root.ui.activeTab, this.handleFavoritesTabLeaving);
  }

  setLoading = (stateName: keyof typeof initialLoadingState, value: boolean) => {
    this.loading[stateName] = value;
  };

  handleLoadsDeletition = (current: string, prev: string) => {
    const currentLoadIds: string[] = JSON.parse(current);
    const prevLoadIds: string[] = JSON.parse(prev);

    if (this.#root.ui.activeTab === tabIds.favorites) {
      const deletedLoadsIds = prevLoadIds.filter(id => !currentLoadIds.includes(id));
      this.setDeletedLoadsIds(deletedLoadsIds);

      if (this.deletedLoadsIds.length) {
        this.updateSelectedLoads(this.deletedLoadsIds);
      }
    }
  };

  handleFavoritesTabLeaving = (_: string, prevTab: string) => {
    if (prevTab === tabIds.favorites) {
      this.updateSearchResults(this.deletedLoadsIds);
      this.setDeletedLoadsIds([]);
    }
  };

  get loads() {
    if (this.favoriteLoads.length) {
      return this.favoriteLoads.map(load => ({
        ...load,
        checked: this.selectedLoads.ids.includes(load.id),
      }));
    }

    return [];
  }

  updateLoad = (updatedLoad: LoadType) => {
    const indexToUpdate = this.favoriteLoads?.findIndex(load => load.id == updatedLoad.id);

    if (this.favoriteLoads && typeof indexToUpdate === 'number') {
      this.favoriteLoads[indexToUpdate] = updatedLoad;
    }
  };

  get loadsIds() {
    return this.favoriteLoads.map(load => load.id);
  }

  get unactiveLoads() {
    return this.favoriteLoads.filter(load => !load.isPublished);
  }

  setDeletedLoadsIds = (deletedLoadsIds: string[]) => {
    this.deletedLoadsIds = deletedLoadsIds;
  };

  setCount = (count: number) => {
    this.count = count;
  };

  setFavoriteLoads = (loads: LoadType[]) => {
    this.favoriteLoads = loads;
  };

  getShouldUpdateSearchResults = (deletedLoadsIds: string[]) => {
    return this.#root.app.loads.some(load => deletedLoadsIds.includes(load.id));
  };

  updateSearchResults = (deletedLoadsIds: string[]) => {
    if (this.getShouldUpdateSearchResults(deletedLoadsIds)) {
      if (this.#root.query.isFromLoadId && this.#root.query.loadId) {
        this.#root.app.searchLoadFromLoadId(this.#root.query.loadId);
      } else {
        this.#root.app.searchLoads({ isNewSearch: false, skipScroll: true });
      }
    }
  };

  updateSelectedLoads = (deletedLoadsIds: string[]) => {
    this.selectedLoads.unselect(deletedLoadsIds);
  };

  updateLoads = async () => {
    await this.fetchFavorites();
  };

  fetchFavorites = async () => {
    try {
      const { data } = await getFavorites({
        take: this.#root.ui.pagination.favoritesPerPage.data,
        skip:
          this.#root.ui.pagination.pages.onlyFavorites * this.#root.ui.pagination.favoritesPerPage.data -
          this.#root.ui.pagination.favoritesPerPage.data,
      });
      const formattedData = snakeToCamelObj(data);

      this.setCount(formattedData.totalItems);
      this.setFavoriteLoads(formattedData.loads.map(this.formatLoad));
    } catch (error) {
      this.#root.errors.setRetrievable({ name: 'getFavorites', message: this.#root.app.i18n.errors.getFavorites });
    }
  };

  removeUnactualFavorites = async () => {
    if (this.unactiveLoads.length === 0) return;

    try {
      await deleteUnactualFavorites();
      this.updateLoads();
    } catch (error) {
      this.#root.errors.setRetrievable({
        name: 'deleteUnactualFavorites',
        message: this.#root.app.i18n.errors.deleteUnactualFavorites,
      });
    }
  };

  removeAllFavorites = async () => {
    try {
      await deleteAllFavorites();
      this.updateLoads();
    } catch (error) {
      this.#root.errors.setRetrievable({
        name: 'deleteAllFavorites',
        message: this.#root.app.i18n.errors.deleteAllFavorites,
      });
    }
  };

  removeFavoritesById = async (ids: string[]) => {
    try {
      this.setLoading('removeSelectedFavorites', true);

      await deleteFavoritesById(ids);
      this.updateLoads();
    } catch (error) {
      this.#root.errors.setRetrievable({
        name: 'deleteFavoritesById',
        message: this.#root.app.i18n.errors.deleteFavoritesById,
      });
    } finally {
      this.setLoading('removeSelectedFavorites', false);
    }
  };

  removeSelectedFavorites = () => {
    this.removeFavoritesById(this.selectedLoads.ids);
  };

  formatLoad = (load: any) => ({
    ...load.cargoApplication,
    isPublished: load.isPublished,
    isFavorite: true,
  });

  handleFavoritesFAQPopoverClose = () => {
    this.#root.enablerFeaturesRepo.markAsViewedByTitle(enablerFeatureTitles.favoritesFAQPopover);
  };
}
